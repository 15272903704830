import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import View from '../../components/atoms/view';
import { scaler } from '../../helper/scaler';

const Layout = styled(View)`
flex: 1;
align-items: center;
justify-content: center;
@media (max-width: 768px) {
    padding: ${({ theme }) => theme.appBase.templatePadding}px;
};
`;

const MessageBox = styled(View)`
`;

const BoxLayout = styled(View)`
align-items: center;
margin-bottom: 16px;
@media (max-width: 768px) {
    margin-bottom: ${scaler(16)};
};
`;

const Title = styled.div`
font-size: ${({ theme }) => theme.webTypography.size.el3}px;
font-weight: ${({ theme }) => theme.webTypography.weight.bold};
color: ${({ theme }) => theme.colors.primary.blue};
text-align: center;
margin-bottom: 24px;
@media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.el3}px;
    margin-bottom: ${scaler(12)}px;
};
`;

const Cancel = observer(() => {
    return (
        <Layout>
            <MessageBox>
                <BoxLayout>
                    <Title>이미 결제가 취소되었습니다.</Title>
                </BoxLayout>
            </MessageBox>
        </Layout>
    );
});

export default Cancel;
