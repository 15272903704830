// react
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { defaultURLParamType } from "../../helper/queryUrlHelper";
import { observer } from "mobx-react";

// service
import orderService from "../../service/orderService";

//store
import paymentStore from "../../store/paymentStore";
import spinnerStore from "../../store/spinnerStore";
import termOfServiceModalStore from "../../modalStore/termOfServiceModalStore";
import privatePolicyModalStore from "../../modalStore/privetePolicyModalStore";
import { initInstance } from "../../factories/axiosFactory";
// view
import styled from "styled-components";
import View from "../../components/atoms/view";
import { scaler } from "../../helper/scaler";
import TOS from "../../components/templates/TOS";
import { localeNumber } from "../../helper/numberHelper";

//component
import BottomDescription from "../../components/templates/bottomDescription";
import Divider from "../../components/atoms/divider";
import Button from "../../components/atoms/button";
import ToggleButton from "../../components/atoms/toggleButton";

// utils
import { PaymentOptions } from "../../constants/paymentMethod";
import ExtraChargeCard from "../../components/atoms/extraChargeCard";
import { ExtraChargePaymentDTO } from "../../interfaces/order/api";

const Layout = styled(View)`
  flex: 1;
`;

export const Flex = styled(View)`
  display: flex;
  flex-direction: row;
`;

export const FlexBetween = styled(View)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const InnerLayout = styled(View)`
  padding: ${({ theme }) => theme.webBase.templatePadding}px;
  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.appBase.templatePadding}px;
  }
`;

const LayoutTitleView = styled(View)`
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(24)}px;
  }
`;

const LayoutTitle = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.el1}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.el1}px;
  }
`;

const TitleView = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(8)}px;
  }
`;

const TotalView = styled(View)`
  margin-top: 30px;
  background-color: ${({ theme }) => theme.colors.gray[100]};
  padding: 20px;
  flex-direction: row;
  border-radius: 8px;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: ${scaler(8)}px;
  }
`;
const Title = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m3}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.appTypography.size.m3}px;
  }
`;

const SeqText = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  color: ${({ theme }) => theme.colors.black[100]};
`;

const SeqView = styled(View)`
  margin-bottom: 30px;
`;

const ExtraModelView = styled(View)`
  margin-bottom: 24px;
`;

const DividerGap = styled(View)`
  margin-bottom: 20px;
`;

const Quantity = styled(View)`
  margin: 0 5px;
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  color: ${({ theme }) => theme.colors.black[100]};
`;

const Price = styled(View)`
  font-size: ${({ theme }) => theme.webTypography.size.m1}px;
  font-weight: ${({ theme }) => theme.webTypography.weight.semiBold};
  color: ${({ theme }) => theme.colors.primary.blue};
`;

const Main = observer(() => {
  const navigation = useNavigate();
  const params: defaultURLParamType = useParams();

  const initParams = (urlParams: defaultURLParamType) => {
    if (!urlParams.host) {
      navigation("/NotFound");
      return;
    }
    if (!urlParams.p) {
      navigation("/NotFound");
      return;
    }
    // All URL params is valid.
    paymentStore.setHost(urlParams.host);
    paymentStore.setP(urlParams.p);
  };

  // 결제 준비된 송장이 존재하는지 확인합니다.
  const validatePayment = (paymentData: ExtraChargePaymentDTO) => {
    if (paymentData?.status === "DONE") {
      // 이미 결제된 사항이 존재하면
      navigation(`/success/${paymentStore.host}/${paymentStore.p}`);
      return false;
    } else if (paymentData?.status === "CANCEL_BEFORE" || paymentData?.status === "CANCELED") {
      navigation('/cancel');
      return false;
    }
    return true;
  };

  const initPaymentData = async () => {
    try {
      spinnerStore.setLoading(true);
      const response = await orderService.getPaymentData(paymentStore.p);
      if (response) {
        paymentStore.setPaymentData(response);
        validatePayment(response);
      }
      spinnerStore.setLoading(false);
    } catch (error: any) {
      spinnerStore.setLoading(false);
      navigation("/NotFound");
    }
  };

  const initPaymentInstance = async () => {
    await paymentStore.generatePaymentInstance();
  };

  useEffect(() => {
    initParams(params);
    initInstance(paymentStore.hostUrl);
    paymentStore.setCurrentTime();
    initPaymentData();
    initPaymentInstance();
  }, []);

  return (
    <Layout>
      <InnerLayout>
        <LayoutTitleView>
          <LayoutTitle>{`${paymentStore.paymentData.orderName} 고객님`}</LayoutTitle>
          <LayoutTitle>결제 페이지</LayoutTitle>
        </LayoutTitleView>

        <SeqView>
          <SeqText>{`주문번호 : ${paymentStore.paymentData.orderSeq}`}</SeqText>
          <SeqText>{`송장번호 : ${paymentStore.paymentData.invoiceSeq}`}</SeqText>
          <SeqText>{`결제번호 : ${paymentStore.paymentData.paymentSeq}`}</SeqText>
        </SeqView>

        {(paymentStore.paymentData?.extraChargesComponent?.length || 0) > 0 && (
          <>
            <TitleView>
              <Title>자재</Title>
            </TitleView>

            <ExtraModelView>
              {paymentStore.paymentData.extraChargesComponent?.map(
                (extraModel, index) => (
                  <ExtraChargeCard
                    key={`extraCharges-${index}`}
                    columnName={extraModel.columnName || ""}
                    price={extraModel.price || 0}
                    quantity={extraModel.quantity || 0}
                    chargeFlag={extraModel.chargeFlag || true}
                  />
                )
              )}
            </ExtraModelView>
          </>
        )}

        {(paymentStore.paymentData?.extraChargesService?.length || 0) > 0 && (
          <>
            <TitleView>
              <Title>서비스</Title>
            </TitleView>

            <ExtraModelView>
              {paymentStore.paymentData.extraChargesService?.map(
                (extraModel, index) => (
                  <ExtraChargeCard
                    key={`extraCharges-service-${index}`}
                    columnName={extraModel.columnName || ""}
                    price={extraModel.price || 0}
                    quantity={extraModel.quantity || 0}
                    chargeFlag={extraModel.chargeFlag || true}
                  />
                )
              )}
            </ExtraModelView>
          </>
        )}

        <TotalView>
          <FlexBetween>
            <Quantity>
              총 {paymentStore.paymentData.extraCharges?.length}건 합계
            </Quantity>
            <Price>{`${localeNumber(
              paymentStore.paymentData.amount || 0
            )} 원`}</Price>
          </FlexBetween>
        </TotalView>
      </InnerLayout>

      <Divider />

      <InnerLayout>
        <TitleView>
          <Title>결제수단</Title>
        </TitleView>
        <ToggleButton
          numPerRow={2}
          value={paymentStore.paymentMethod}
          onChange={paymentStore.setPaymentMethod}
          options={PaymentOptions}
          size="medium"
        />
      </InnerLayout>

      <DividerGap>
        <Divider />
      </DividerGap>

      <InnerLayout>
        <Button
          title={`${localeNumber(
            paymentStore.paymentData?.amount || 0
          )}원 결제하기`}
          onClick={() => {
            if (!paymentStore.paymentMethod) {
              console.log("결제 수단을 선택해주세요.");
              return;
            }
            orderService.getPaymentData(paymentStore.p).then((response) => {
              if (response) {
                paymentStore.setPaymentData(response);
                const flag = validatePayment(response);
                if (!flag) return;
              }
              paymentStore.activePaymentInstance(paymentStore.paymentMethod!);
            }).catch(() => {
              navigation(`/fail/${paymentStore.host}/${paymentStore.p}`);
            });
          }}
          size="medium"
          disabled={paymentStore.paymentMethod === undefined}
        />
      </InnerLayout>

      <InnerLayout>
        <TOS
          onPressTOS={() => termOfServiceModalStore.handleModal(true)}
          onPressPrivatePolicy={() => privatePolicyModalStore.handleModal(true)}
        />
      </InnerLayout>

      <InnerLayout>
        <BottomDescription />
      </InnerLayout>
    </Layout>
  );
});

export default Main;
